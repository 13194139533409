export const STYLABLE_DELIMITER = '__';
export const STYLABLE_VARIABLE_DELIMITER = '-';

export const generateStylableClass = (styleId, className) =>
  `${styleId}${STYLABLE_DELIMITER}${className}`;

export const generateStylableVariable = (styleId, variableName) =>
  `--${styleId}${STYLABLE_VARIABLE_DELIMITER}${variableName}`;

/**
 * Creates a StylableEditor configuration for setting the 'hidden' property of
 * a specified component element selector state
 * @param {string} selector - The element selector to set
 * @param {string} state - The state to set
 * @param {boolean} hidden - The value to set
 */
export const createStateSetHiddenConfig = (selector, state, hidden) => ({
  selectorConfiguration: {
    [selector]: {
      stateNameOverrides: {
        [state]: {hidden},
      },
    },
  },
});

/**
 * Creates a StylableEditor configuration for setting the 'hide' property of
 * a specified component element selector
 * @param {string} selector - The element selector to set
 * @param {boolean} hide - The value to set
 */
export const createSelectorSetHideConfig = (selector, hide) => ({
  selectorConfiguration: {
    [selector]: {hide},
  },
});

export const combineSelectorConfigurations = (...selectorConfigurations) => ({
  selectorConfiguration: {
    ...selectorConfigurations.reduce(
      (acc, configuration) =>
        configuration.selectorConfiguration ?
          {...acc, ...configuration.selectorConfiguration} :
          {...acc, ...configuration},
      {},
    ),
  },
});
