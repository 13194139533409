import React from 'react';
import {SantaComponentHelperMethods} from 'santa-components';

export class SantaComponent extends React.Component {
  getRequiredProps(...args) {
    return SantaComponentHelperMethods.getRequiredProps(this, ...args);
  }

  updateData(...args) {
    return SantaComponentHelperMethods.updateData(this, ...args);
  }

  updateProps(...args) {
    return SantaComponentHelperMethods.updateProps(this, ...args);
  }
}

SantaComponent.propTypes = SantaComponentHelperMethods.requiredPropTypes;
