import * as React from 'react';
import {PropTypes} from 'prop-types';
import {st, classes} from './StylableButton.st.css';
import {getDataAttributes} from '../../utils/attributesUtils';

const KEY_CODES = {ENTER: 13, SPACE: 32};
export class StylableButton extends React.Component {
  getCommonAttributes = () => {
    const ariaAttributes = {};

    if (typeof this.props.ariaPressed === 'boolean') {
      ariaAttributes['aria-pressed'] = this.props.ariaPressed;
    }

    if (typeof this.props.ariaExpanded === 'boolean') {
      ariaAttributes['aria-expanded'] = this.props.ariaExpanded;
    }

    if (this.props.ariaHaspopup) {
      ariaAttributes['aria-haspopup'] = this.props.ariaHaspopup;
    }

    if (typeof this.props.tabindex === 'number') {
      ariaAttributes.tabindex = this.props.tabindex;
    }

    const dataAttr = getDataAttributes(this.props);

    return {
      ...dataAttr,
      'aria-label': this.props.label,
      className: st(classes.root, {error: this.props.hasError, disabled: this.props.disabled}, this.props.className),
      onKeyPress: this.onKeyPressEventHandler,
      onClick: this.props.disabled ? null : this.props.onClick,
      onDoubleClick: this.props.disabled ? null : this.props.onDoubleClick,
      onMouseEnter: this.props.onMouseEnter,
      onMouseLeave: this.props.onMouseLeave,
      style: this.props.style,
      id: this.props.id,
      ...ariaAttributes
    };
  }

  renderButtonContent = () =>
    (<div className={classes.container}>
      {this.props.label ? <span className={classes.label} style={this.props.style ? {color: this.props.style.color} : {}}>{this.props.label}</span> : null}
      {this.props.icon ? <span className={classes.icon} aria-hidden="true">{this.props.icon}</span> : null}
    </div>)

  renderLinkedButton = () => {
    const {className, ...restOfAttr} = this.getCommonAttributes();
    return (<a
      href={this.props.disabled ? '#' : this.props.href}
      aria-haspopup={this.props.ariaHaspopup}
      target={this.props.target}
      {...restOfAttr}
      className={className + ' ' + classes.link}>
      {this.renderButtonContent()}
    </a>);
  }

  renderRegularButton = () =>
    (<button type="button" disabled={this.props.disabled} {...this.getCommonAttributes()}>
      {this.renderButtonContent()}
    </button>)

  render() {
    const {href} = this.props;
    return (
      href ? this.renderLinkedButton() : this.renderRegularButton()
    );
  }

  onKeyPressEventHandler = e => {
    if (!this.props.href && e.keyCode === KEY_CODES.ENTER) {
      this.props.onClick && this.props.onClick();
    } else if (this.props.href && e.keyCode === KEY_CODES.SPACE) {
      this.props.onClick && this.props.onClick();
    }
  };
}

StylableButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  style: PropTypes.object,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  tabindex: PropTypes.number,
  ariaPressed: PropTypes.bool,
  ariaExpanded: PropTypes.bool,
  ariaHaspopup: PropTypes.string,
  'data-anchor-comp-id': PropTypes.string,
  'data-keep-roots': PropTypes.string,
  'data-anchor': PropTypes.string
};
